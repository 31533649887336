
import {defineComponent} from "vue";

export default defineComponent({
  name: "CardButton",
  props: {
    icon: { type: String },
    text: { type: String },
    small: { type: Boolean, default: false },
    height: { type: String, default: '160' },
    disabled: { type: Boolean, default: false }
  },
});
