import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['sqg-cardButton clickable', { '-small' : _ctx.small }, { '-disabled' : _ctx.disabled }]),
    style: _normalizeStyle(`height: ${_ctx.height}px`)
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.icon)
    }, null, 2),
    _createElementVNode("div", null, _toDisplayString(_ctx.text), 1)
  ], 6))
}